import { render, staticRenderFns } from "./safetyHealthyMstExcelUpload.vue?vue&type=template&id=704358c6"
import script from "./safetyHealthyMstExcelUpload.vue?vue&type=script&lang=js"
export * from "./safetyHealthyMstExcelUpload.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\NewDevelopment\\SHMS\\FrontEnd\\PSMFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('704358c6')) {
      api.createRecord('704358c6', component.options)
    } else {
      api.reload('704358c6', component.options)
    }
    module.hot.accept("./safetyHealthyMstExcelUpload.vue?vue&type=template&id=704358c6", function () {
      api.rerender('704358c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sai/sht/safetyHealthyMstExcelUpload.vue"
export default component.exports